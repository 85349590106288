<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" class="sidenav">
    <app-sidenav></app-sidenav>
  </mat-sidenav>

  <mat-sidenav-content class="app-wrapper">
    <header>
      <mat-toolbar color="primary" style="height: 100%">
        <button
          mat-icon-button
          aria-label="Menu icon"
          (click)="sidenav.toggle()"
        >
          <mat-icon>menu</mat-icon>
        </button>
        <span>Humanitas - CV App</span>
        <span class="spacer"></span>
        <button mat-icon-button aria-label="Home page icon" routerLink="/">
          <mat-icon>home</mat-icon>
        </button>
        <button mat-icon-button aria-label="Support icon" (click)="getInfo()">
          <mat-icon>help</mat-icon>
        </button>
        <mat-chip-listbox aria-label="User email">
          <mat-chip-option *ngIf="user"
            >{{ user.name }} {{ user.surname }} - {{ user.role }}</mat-chip-option
          >
        </mat-chip-listbox>
      </mat-toolbar>
    </header>

    <div class="loader-container" *ngIf="!user && !unauth">
      <div>
        <mat-spinner></mat-spinner>
      </div>
    </div>
    <div class="app-body" *ngIf="user">
      <router-outlet></router-outlet>
    </div>
    <div class="app-body" *ngIf="unauth">
      <app-unauthorized></app-unauthorized>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
