import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./auth.guard";
import { HomeComponent } from "./components/home/home.component";
import { HospitalsComponent } from "./components/hospitals/hospitals.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { StaffInfoComponent } from "./components/staff-info/staff-info.component";
import { StaffComponent } from "./components/staff/staff.component";
import { UnauthorizedComponent } from "./components/unauthorized/unauthorized.component";
import { StatisticsModule } from "./components/statistics/statistics.module";

const routes: Routes = [
  {
    path: "unauthorized",
    component: UnauthorizedComponent,
  },
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: {
      role: ["ADMIN", "HR", "DMS", "DOC", "COMM"],
    },
  },
  {
    path: "",
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: {
      role: ["ADMIN", "HR", "DMS", "DOC", "COMM"],
    },
  },
  {
    path: "hospitals",
    component: HospitalsComponent,
    canActivate: [AuthGuard],
    data: {
      role: ["ADMIN", "HR", "DMS", "DOC", "COMM"],
    },
  },
  {
    path: "staff",
    component: StaffComponent,
    canActivate: [AuthGuard],
    data: {
      role: ["ADMIN"],
    },
  },
  {
    path: "staff-info",
    component: StaffInfoComponent,
    canActivate: [AuthGuard],
    data: {
      role: ["ADMIN"],
    },
  },
  {
    canActivate: [AuthGuard],
    data: { role: ["ADMIN"] },
    path: "statistics",
    loadChildren: () =>
      import("./components/statistics/statistics.module").then(
        (mod) => mod.StatisticsModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
