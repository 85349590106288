import { Component, OnInit } from '@angular/core';
import { Metadata } from 'src/app/models/metadata';
import { MetadataService } from 'src/app/services/metadata/metadata.service';

@Component({
  selector: 'app-status-key',
  templateUrl: './status-key.component.html',
  styleUrls: ['./status-key.component.scss'],
})
export class StatusKeyComponent implements OnInit {
  constructor(public metadataService: MetadataService) {}

  ngOnInit(): void {}

  metadata() {
    return JSON.stringify(this.metadataService.getMetadata());
  }
}
