<div>
  <app-notifications-section
    class="home"
    [notifications]="notifications"
    [notificationsFilter]="notificationsFilter"
    (applyNotificationFilter)="toggleNotificationFilter($event)"
  ></app-notifications-section>
  <app-dashboard-table-section
    [data]="data"
    (changeInHospitalFilter)="setHospitalFilter($event)"
  ></app-dashboard-table-section>
</div>
