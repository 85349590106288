import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/services/user/user.service";
import { DashboardService } from "src/app/services/dashboard/dashboard.service";
import { Dashboard } from "src/app/models/dashboard";
import { DashboardNotification } from "src/app/models/dashboard-notification";
import { User } from "src/app/models/user";

@Component({
  selector: "app-home-doctor",
  templateUrl: "./home-doctor.component.html",
  styleUrls: ["./home-doctor.component.scss", "../../home/home.component.scss"],
})
export class HomeDoctorComponent implements OnInit {
  centered = false;
  disabled = false;
  unbounded = false;
  notifTest: string = "all";
  dashboard: Dashboard;
  notifications: DashboardNotification[] = [];
  user: User | null = null;

  constructor(
    private userService: UserService,
    private route: Router,
    private router: ActivatedRoute,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.user = this.userService.loggedUser();
    this.dashboardService.getDashboard().subscribe({
      next: (data) => {
        this.dashboard = data;
        this.notifications = this.dashboard.notifications;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  ngAfterViewInit() {
    //this.dataSource.filter = 'Helium';
  }

  goToProfile() {
    this.route.navigate(["/profile"]);
  }
}
