<div *ngIf="user">
  <div *ngIf="user.role == 'HR'">
    <app-home-hr></app-home-hr>
  </div>
  <div *ngIf="user.role == 'DOC'">
    <app-home-doctor></app-home-doctor>
  </div>
  <div *ngIf="user.role == 'DMS'">
    <app-home-dms></app-home-dms>
  </div>
  <div *ngIf="user.role == 'ADMIN'">
    <app-home-admin></app-home-admin>
  </div>
  <div *ngIf="user.role == 'COMM'">
    <app-home-comm></app-home-comm>
  </div>
</div>
