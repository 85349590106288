<h1 mat-dialog-title>Sei sicuro di confermare questo profilo?</h1>
<div mat-dialog-content>
  <div *ngIf="!data.single">
    <p>{{ data.message }}</p>
    <mat-form-field>
      <mat-label>Seleziona ruolo</mat-label>
      <mat-select [(ngModel)]="end_profile_status_id">
        <mat-option
          *ngFor="let transition of data.options"
          [value]="transition.end_profile_status_id"
        >
          {{ transition.message }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Note</mat-label>
      <textarea
        matInput
        placeholder="Inserisci un messaggio"
        [(ngModel)]="note"
      ></textarea>
    </mat-form-field>
  </div>
  <div *ngIf="data.single">
    <p>{{ data.message }}</p>
  </div>
</div>
<div mat-dialog-actions class="actions-container" *ngIf="data.single">
  <button mat-button (click)="onNoClick()">ANNULLA</button>
  <button mat-button [mat-dialog-close]="'single'" cdkFocusInitial>OK</button>
</div>

<div mat-dialog-actions class="actions-container" *ngIf="!data.single">
  <button mat-button (click)="onNoClick()">ANNULLA</button>
  <button
    mat-button
    [mat-dialog-close]="{ profile_status: end_profile_status_id, note: note }"
    cdkFocusInitial
  >
    OK
  </button>
</div>
