import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Dashboard } from "src/app/models/dashboard";
import { DashboardNotification } from "src/app/models/dashboard-notification";
import { TableDoctor } from "src/app/models/table-doctor";
import { ApiService } from "../api/api.service";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private api: ApiService) {}

  public getDashboard(): Observable<Dashboard> {
    return this.api.getDashboard();
  }

  public filterDashboard(
    dashboard: Dashboard,
    notification: DashboardNotification
  ): TableDoctor[] {
    if (notification.filter == "status") {
      if (notification.type == 0) {
        return dashboard.doctors.filter((o) =>
          Object.values(o.status).includes(notification.value)
        );
      } else {
        return dashboard.doctors.filter((o) =>
          Object.values(o.status).every((x) => x == notification.value)
        );
      }
    } else if (notification.filter == "photo") {
      return dashboard.doctors.filter((o) => o.photo == notification.value);
    } else return dashboard.doctors;
  }
}
