import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TableDoctor } from "src/app/models/table-doctor";
import { ApiService } from "src/app/services/api/api.service";

@Component({
  selector: "app-dashboard-table-section",
  templateUrl: "./dashboard-table-section.component.html",
  styleUrls: ["./dashboard-table-section.component.scss"],
})
export class DashboardTableSectionComponent implements OnInit {
  hospitalFilter: number[] = [];
  @Input() data: TableDoctor[] = [];
  filter: string = "";
  @Output() changeInHospitalFilter = new EventEmitter<number[]>();
  hospitalDownloadList: number[] = [];
  loading: boolean = false;

  constructor(private api: ApiService) {}

  ngOnInit(): void {}

  addHospitalFilter(hospitalId: number) {
    this.hospitalFilter.push(hospitalId);
    // this._setDatasource();
    this.changeInHospitalFilter.emit(this.hospitalFilter);
  }

  removeHospitalFilter(hospitalId: number) {
    const index = this.hospitalFilter.indexOf(hospitalId);

    if (index >= 0) {
      this.hospitalFilter.splice(index, 1);
    }
    // this._setDatasource();
    this.changeInHospitalFilter.emit(this.hospitalFilter);
  }

  addHospitalDownload(hospitalId: number) {
    this.hospitalDownloadList.push(hospitalId);
  }

  removeHospitalDownload(hospitalId: number) {
    const index = this.hospitalDownloadList.indexOf(hospitalId);
    if (index >= 0) {
      this.hospitalDownloadList.splice(index, 1);
    }
  }

  downloadAnag() {
    this.loading = true;
    this.api.getDownloadAnag(this.hospitalDownloadList).subscribe({
      next: (data) => {
        window.open(data.url);
        this.loading = false;
      },
      error: (error) => {
        console.log(error);
        this.loading = false;
      },
    });
  }
}
