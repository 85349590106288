<div class="mat-elevation-z8" style="background-color: white">
  <div class="table-banner">
    <h3>Staff</h3>
    <mat-divider></mat-divider>
    <form class="home-filter-form">
      <mat-form-field class="home-filter-form-field">
        <mat-label>Filtro</mat-label>
        <input
          [(ngModel)]="filter"
          name="filter"
          matInput
          (ngModelChange)="filterDataSource(filter)"
        />
      </mat-form-field>
    </form>
  </div>
  <div>
    <div class="staff-add-button-row">
      <button
        mat-fab
        color="primary"
        aria-label="Example icon button with a delete icon"
        [routerLink]="['/staff-info']"
        routerLinkActive="active"
        mat-row
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <table mat-table [dataSource]="dataSource" matSort>
      <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

      <!-- Name Column -->
      <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.first_name }}</td>
      </ng-container>

      <!-- surname Column -->
      <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Cognome</th>
        <td mat-cell *matCellDef="let element">{{ element.last_name }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="role_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ruolo</th>
        <td mat-cell *matCellDef="let element">{{ element.role_name }}</td>
      </ng-container>

      <ng-container matColumnDef="delete_action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Azioni</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            color="primary"
            aria-label="edit staff"
            [routerLink]="['/staff-info']"
            [queryParams]="{ n: element.id }"
            routerLinkActive="active"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            color="primary"
            aria-label="delete staff"
            (click)="deleteStaff(element.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Seleziona pagina per lo staff"
    >
    </mat-paginator>
  </div>
</div>
