import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { filter, map, Observable, tap } from "rxjs";
import { UserService } from "./services/user/user.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard  {
  constructor(public userService: UserService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkUserLogin(route, state);
  }
  checkUserLogin(route: ActivatedRouteSnapshot, url: any): Observable<boolean> {
    return this.userService.isAuthenticated$.pipe(
      filter((x) => x),
      map((item) => {
        const userRole = this.userService.getUserRole();
        if (route.data.role && route.data.role.indexOf(userRole) === -1) {
          this.router.navigate(["/unauthorized"]);
          return false;
        }
        return true;
      })
    );
  }
}
