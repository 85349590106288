import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/models/dialog-data';

@Component({
  selector: 'app-save-profile-dialog',
  templateUrl: './save-profile-dialog.component.html',
  styleUrls: ['./save-profile-dialog.component.scss'],
})
export class SaveProfileDialogComponent implements OnInit {
  end_profile_status_id: number;
  note: string;

  constructor(
    public dialogRef: MatDialogRef<SaveProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    if (!this.data.single) {
      this.end_profile_status_id = this.data.options[0].end_profile_status_id;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
