import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DraftContent } from "src/app/models/draft-content";
import { Visibility } from "src/app/models/visibility";
import { ProfileResponse } from "../../models/profile-response";
import { ApiService } from "../api/api.service";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  constructor(private api: ApiService) {}

  public getProfile(user_id: string): Observable<ProfileResponse> {
    return this.api.getProfile(user_id);
  }

  public saveProfileHospitals(user_id: string, payload: any): Observable<any> {
    return this.api.saveProfileHospitals(user_id, payload);
  }

  public saveProfile(
    user_id: string,
    hospital_id: number,
    payload: any
  ): Observable<any> {
    return this.api.saveProfile(user_id, hospital_id, payload);
  }

  public forcePublishProfile(
    user_id: string,
    hospital_id: number,
    payload: any
  ): Observable<any> {
    return this.api.forcePublishProfile(user_id, hospital_id, payload);
  }

  public approveProfile(
    user_id: string,
    hospital_id: number,
    payload: any
  ): Observable<any> {
    return this.api.approveProfile(user_id, hospital_id, payload);
  }

  public denyProfile(
    user_id: string,
    hospital_id: number,
    payload: any
  ): Observable<any> {
    return this.api.denyProfile(user_id, hospital_id, payload);
  }

  public loadDraft(draft_id: number): Observable<DraftContent[]> {
    return this.api.loadDraft(draft_id);
  }

  public saveProfilePhoto(user_id: string, body: FormData): Observable<any> {
    return this.api.savePhoto(user_id, body);
  }

  public switchVisibility(
    user_id: string,
    hospital_id: number
  ): Observable<Visibility> {
    return this.api.switchVisibility(user_id, hospital_id);
  }

  public setHrFields(
    hospital_id: number,
    user_id: string,
    startContract: string | null
  ): Observable<any> {
    return this.api.setHrFields(hospital_id, user_id, startContract);
  }
}
