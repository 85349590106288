<div class="profile-container">
  <div class="mat-elevation-z8 profile-card">
    <div *ngIf="profileResponse">
      <div class="profile-banner-row">
        <div>
          <form>
            <img
              *ngIf="profileResponse.user.photo_url"
              class="profile-img select-img"
              [src]="profileResponse.user.photo_url"
              (click)="imageInput.click()"
              alt="profile-pic"
            />
            <img
              *ngIf="!profileResponse.user.photo_url"
              class="profile-img select-img"
              src="../../../assets/img/doctor-placeholder.svg"
              (click)="imageInput.click()"
              alt="profile-pic"
            />
            <input
              #imageInput
              hidden="true"
              type="file"
              onclick="this.value=null"
              (change)="imageInputChange($event)"
              accept="image/png, image/jpeg"
            />
          </form>
        </div>

        <div class="profile-banner-info">
          <h2>
            {{ profileResponse.user.name }} {{ profileResponse.user.surname }}
          </h2>
        </div>
      </div>
      <mat-tab-group>
        <mat-tab label="Informazioni personali">
          <app-profile-form
            [hospital_id]="0"
            [user_id]="profile_id"
            [questions]="profileResponse.user.user_fields"
            [readonly]="profileResponse.user.readonly"
            [draft]="profileResponse.drafts[0]"
            [profile_id]="profile_id"
            (reload)="reload($event)"
          ></app-profile-form>
        </mat-tab>
        <mat-tab label="Ospedali" *ngIf="['HR', 'ADMIN'].includes(user.role)">
          <app-hospital-form
            [user_hospitals]="profileResponse.profile_status"
            [profile_id]="profile_id"
            (reload)="reload($event)"
          ></app-hospital-form>
        </mat-tab>
        <mat-tab
          *ngFor="let hospital_profile of profileResponse.profile | keyvalue"
          [label]="profileResponse.hospitals[+hospital_profile.key]"
          [id]="profileResponse.hospitals[+hospital_profile.key]"
        >
          <app-profile-form
            [hospital_id]="+hospital_profile.key"
            [user_id]="profile_id"
            [profileData]="hospital_profile.value"
            [metadata]="metadataService.getMetadata()"
            [status]="profileResponse.profile_status[+hospital_profile.key]"
            [draft]="profileResponse.drafts[+hospital_profile.key]"
            [actions]="hospital_profile.value.actions"
            [profile_id]="profile_id"
            (reload)="reload($event)"
          ></app-profile-form>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div *ngIf="!profileResponse">
      <mat-spinner class="spinner-container"></mat-spinner>
    </div>
  </div>
</div>
