<div>
  <div *ngIf="user">
    <div class="home">
      <div
        *ngFor="let item of notifications; let i = index"
        class="notification-container mat-elevation-z4"
        [ngClass]="{
          'sap-notification': item.type == 0,
          'hr-notification': item.type == 1,
          'doc-notification': item.type == 2,
          'hr2-notification': item.type == 3,
          'comm-notification': item.type == 4,
          'published-notification': item.type == 5,
          'cancelled-notification': item.type == 6
        }"
        matRipple
        [matRippleCentered]="centered"
        [matRippleDisabled]="disabled"
        [matRippleUnbounded]="unbounded"
        [routerLink]="['/profile']"
        [queryParams]="{ n: user.user_id }"
        routerLinkActive="active"
      >
        <h3 class="number-home-element">
          {{ item.value }}
        </h3>
        <div>{{ item.title }}</div>
      </div>
    </div>

    <div>
      <app-status-key></app-status-key>
    </div>
  </div>
</div>
