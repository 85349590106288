<div [formGroup]="form">
  <div [ngSwitch]="question.controlType">
    <div *ngSwitchCase="'email'" class="email-question">
      <mat-form-field appearance="outline">
        <mat-label>{{ question.label }}</mat-label>
        <input
          matInput
          [formControlName]="question.key + 'value'"
          [id]="question.key + 'value'"
          [type]="question.type"
          [placeholder]="question.placeholder"
          [value]="question.value"
          [readonly]="readonly"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Dominio</mat-label>
        <mat-select
          [id]="question.key + 'domain'"
          [formControlName]="question.key + 'domain'"
          [disabled]="readonly"
        >
          <mat-option
            *ngFor="let opt of metadataService.getMetadata().domains"
            [value]="opt"
          >
            {{ opt }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field
      class="full-width"
      *ngSwitchCase="'text'"
      appearance="outline"
    >
      <mat-label>{{ question.label }}</mat-label>
      <input
        matInput
        [formControlName]="question.key"
        [id]="question.key"
        [type]="question.type"
        [placeholder]="question.placeholder"
        [value]="question.value"
        [readonly]="readonly"
      />
    </mat-form-field>
    <mat-form-field *ngSwitchCase="'dropdown'" appearance="outline">
      <mat-label>{{ question.label }}</mat-label>
      <mat-select
        [id]="question.key"
        [formControlName]="question.key"
        [disabled]="readonly"
      >
        <mat-option *ngFor="let opt of question.options" [value]="opt.key">
          {{ opt.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngSwitchCase="'textbox'" class="rte-editor-question">
      <mat-label>{{ question.label }}</mat-label>
      <app-rte-editor
        [readonly]="readonly"
        [placeholder]="question.placeholder"
        [controlName]="question.key"
        [id]="question.key"
        [value]="question.value"
        [groupName]="form"
      ></app-rte-editor>
      <div>
        {{
          htmlLengthService.htmlMaxLength(form.controls[question.key].value)
        }}/1000
      </div>
    </div>
  </div>
  <div *ngIf="question.controlType != 'email'" class="error-text">
    <div
      class="errorMessage"
      *ngIf="form.controls[question.key].hasError('required')"
    >
      {{ question.label }} is required
    </div>
    <div
      *ngIf="
        form.controls[question.key].hasError('maxlength') ||
        form.controls[question.key].hasError('htmlmaxlength')
      "
    >
      Superato limiti caratteri
    </div>
  </div>
</div>
