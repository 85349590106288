import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  ControlValueAccessor,
  FormGroup,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
} from "@angular/forms";
import { Editor, Toolbar } from "ngx-editor";

@Component({
  selector: "app-rte-editor",
  templateUrl: "./rte-editor.component.html",
  styleUrl: "./rte-editor.component.scss",
})
export class RteEditorComponent implements OnInit, OnDestroy {
  editor: Editor;
  toolbar: Toolbar = [
    // default value
    ["bold", "italic"],
    ["underline", "strike"],
    ["ordered_list", "bullet_list"],
    ["link", "image"],
    ["text_color"],
    ["align_left", "align_center", "align_right", "align_justify"],
    ["horizontal_rule", "format_clear"],
  ];
  @Input() readonly: boolean = false;
  @Input() placeholder: string = "";
  @Input() value: string | undefined = "";
  @Input() controlName: string;
  @Input() public groupName: UntypedFormGroup;
  onChange = (value: string) => {};

  onTouched = () => {};

  ngOnInit(): void {
    this.editor = new Editor({ attributes: { style: "min-height: 100px;" } });
  }

  // make sure to destory the editor
  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
