<div class="status-key">
  <div
    class="status-key-row"
    *ngFor="let item of metadataService.getMetadata().status | keyvalue"
  >
    <div
      class="circle"
      [ngClass]="{
        'sap-notification': item.key == '0',
        'hr-notification': item.key == '1',
        'doc-notification': item.key == '2',
        'hr2-notification': item.key == '3',
        'comm-notification': item.key == '4',
        'published-notification': item.key == '5',
        'cancelled-notification': item.key == '6'
      }"
    ></div>
    <div>{{ item.value }}</div>
  </div>
</div>
