export const environment = {
  production: true,
  backendUrl: "https://backend-vnmmjq43jq-ey.a.run.app",
  firebase: {
    locationId: "europe-west3",
    apiKey: "AIzaSyCOcgQLTiK300UgsFeNDr3B6buEYcN51xk",
    authDomain: "goreply-humanitas-cv-test.firebaseapp.com",
    projectId: "goreply-humanitas-cv-test",
    storageBucket: "goreply-humanitas-cv-test.appspot.com",
    messagingSenderId: "422535951920",
    appId: "1:422535951920:web:da25b04c9fe97632549ff5",
  },
  provider: "saml.adfs.humanitas.stage",
};
