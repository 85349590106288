import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { Metadata } from 'src/app/models/metadata';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  private metadata = new BehaviorSubject({
    status: {},
  } as Metadata);

  public getMetadata() {
    return this.metadata.getValue();
  }

  constructor(private apiService: ApiService) {}

  public async getMetadataFromBackend() {
    let metadata = await lastValueFrom(this.apiService.getMetadata());
    this.metadata.next(metadata);
  }
}
