<div
  *ngFor="let item of notifications; let i = index"
  class="notification-container mat-elevation-z4"
  [ngClass]="{
    'notification-container-toggled': notificationsFilter[i],
    'notification-container-untoggled': !notificationsFilter[i]
  }"
  matRipple
  [matRippleCentered]="centered"
  [matRippleDisabled]="disabled"
  [matRippleUnbounded]="unbounded"
  (click)="toggleNotificationFilter(i, item)"
>
  <h1 class="number-home-element">
    {{ item.size }}
  </h1>
  <div class="notification-title">{{ item.title }}</div>
</div>
