import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Actions } from "src/app/models/actions";
import { Draft } from "src/app/models/draft";
import { Metadata } from "src/app/models/metadata";
import { ProfileData } from "src/app/models/profile-data";
import { QuestionBase } from "src/app/models/question/question-base";
import { ClipboardService } from "src/app/services/clipboard/clipboard.service";
import { ProfileService } from "src/app/services/profile/profile.service";
import { QuestionControlService } from "src/app/services/question-control/question-control.service";
import { UserService } from "src/app/services/user/user.service";
import { SaveProfileDialogComponent } from "../save-profile-dialog/save-profile-dialog.component";
import { ForcePublishDialogComponent } from "../force-publish-dialog/force-publish-dialog.component";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "app-profile-form",
  templateUrl: "./profile-form.component.html",
  styleUrls: ["./profile-form.component.scss"],
})
export class ProfileFormComponent implements OnInit {
  form!: UntypedFormGroup;
  @Input() questions: QuestionBase<string>[];
  @Input() user_id: string;
  @Input() hospital_id: number;
  @Input() status!: number;
  @Input() metadata: Metadata;
  @Input() profileData: ProfileData;
  @Input() readonly!: boolean;
  @Input() draft!: Draft;
  @Input() actions: Actions;
  @Input() profile_id: string;
  @Output() reload = new EventEmitter<string>();
  loading: boolean = false;
  modified: boolean = false;
  userRole: string;
  contractStart: string | null;

  constructor(
    public userService: UserService,
    private profileService: ProfileService,
    private qcs: QuestionControlService,
    private _snackBar: MatSnackBar,
    private clipboardService: ClipboardService,
    public dialog: MatDialog,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.userRole = this.userService.getUserRole();
    if (this.hospital_id == 0) {
      this.form = this.qcs.toFormGroup(
        this.questions as QuestionBase<string>[]
      );
    } else {
      let allQuestions: QuestionBase<string>[] = [];
      for (let subkey of this.profileData.sections) {
        allQuestions = allQuestions.concat(subkey.questions);
      }
      this.form = this.qcs.toFormGroup(allQuestions as QuestionBase<string>[]);
      this.contractStart = this.profileData.fields.contract_start;
    }
    let initialForm = this.form.getRawValue();
    this.form.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        this.modified = false;
        for (const key in initialForm) {
          if ((initialForm[key] ?? "") != (value[key] ?? "")) {
            this.modified = true;
            break;
          }
        }
      });
  }
  saveHrFields() {
    this.loading = true;
    this.profileService
      .setHrFields(this.hospital_id, this.user_id, this.contractStart)
      .subscribe({
        next: (res) => {
          this.loading = false;
        },
        error: (err) => {
          console.error(err);
          this.loading = false;
        },
      });
  }

  checkDateFormat(value: string) {
    if (value) {
      // First check for the pattern
      if (!/^\d{2}\/\d{2}\/\d{4}$/.test(value)) return false;

      // Parse the date parts to integers
      var parts = value.split("/");
      var day = parseInt(parts[0], 10);
      var month = parseInt(parts[1], 10);
      var year = parseInt(parts[2], 10);

      // Check the ranges of month and year
      if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

      var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

      // Adjust for leap years
      if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
        monthLength[1] = 29;

      // Check the range of the day
      return day > 0 && day <= monthLength[month - 1];
    } else {
      return true;
    }
  }
  switchVisibility() {
    this.loading = true;
    this.profileService
      .switchVisibility(this.user_id, this.hospital_id)
      .subscribe({
        next: (res) => {
          console.log(res);
          this.profileData.visibility = res.visibility;
          this.loading = false;
          this.reload.emit("reload");
        },
        error: (err) => {
          console.error(err);
          this.loading = false;
        },
      });
  }

  onSubmit(event: SubmitEvent) {
    this.loading = true;
    if (event.submitter?.id == "saveDraft") {
      let request = JSON.stringify(this.form.getRawValue());
      this.saveDraft(request);
    } else if (event.submitter?.id == "approveDraft") {
      let request = JSON.stringify(
        this.formatFormValues(this.form.getRawValue())
      );
      this.approveProfile(request);
    } else if (event.submitter?.id == "copyForm") {
      this.copyForm(JSON.stringify(this.form.getRawValue()));
    } else if (event.submitter?.id == "forcePublish") {
      this.forcePublish(JSON.stringify(this.form.getRawValue()));
    }
  }

  formatFormValues(values: any) {
    console.log(values);
    if ("emailvalue" in values && "emaildomain" in values) {
      values["email"] = values["emailvalue"] + values["emaildomain"];
      delete values["emailvalue"];
      delete values["emaildomain"];
    }
    console.log(values);
    return values;
  }

  saveDraft(request: string) {
    console.log("payload", request);
    this.profileService
      .saveProfile(this.user_id, this.hospital_id, request)
      .subscribe({
        next: (res) => {
          console.log(res);
          this.draft = { hospital_id: this.hospital_id, id: res["draft_id"] };
          this._snackBar.open("Bozza salvata", "OK");
          this.loading = false;
        },
        error: (err) => {
          console.error(err);
          this.loading = false;
        },
      });
  }

  approveProfile(body: string) {
    let request = {
      body: JSON.parse(body),
      end_profile_status: null,
      note: null,
      modified: this.modified,
    };
    let dialogRef;
    if (this.actions) {
      if (this.actions.approve.length == 1) {
        dialogRef = this.dialog.open(SaveProfileDialogComponent, {
          data: {
            single: true,
            message:
              "Premendo su 'Ok', confermi le modifiche effettuate al profilo",
          },
        });
      } else {
        dialogRef = this.dialog.open(SaveProfileDialogComponent, {
          data: {
            single: false,
            message:
              "Scegli il ruolo che dovrà sistemare il profilo e conferma premendo 'Ok'",
            options: this.actions.approve,
          },
        });
      }
    } else {
      dialogRef = this.dialog.open(SaveProfileDialogComponent, {
        data: {
          single: true,
          message:
            "Premendo su 'Ok', confermi le modifiche effettuate al profilo",
        },
      });
    }
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result || result == 0) {
        if (result == "single") {
          console.log("payload", request);
          this.profileService
            .approveProfile(this.user_id, this.hospital_id, request)
            .subscribe({
              next: (res) => {
                console.log(res);
                this._snackBar.open("Profilo salvato", "OK");
                this.loading = false;
                this.reload.emit("reload");
              },
              error: (err) => {
                console.error(err);
                this.loading = false;
              },
            });
        } else {
          request.end_profile_status = result.profile_status;
          request.note = result.note;
          this.profileService
            .approveProfile(this.user_id, this.hospital_id, request)
            .subscribe({
              next: (res) => {
                console.log(res);
                this._snackBar.open("Profilo salvato", "OK");
                this.loading = false;
                this.reload.emit("reload");
              },
              error: (err) => {
                console.error(err);
                this.loading = false;
              },
            });
        }
      } else {
        this.loading = false;
      }
    });
  }

  forcePublish(body: string) {
    let request = {
      body: JSON.parse(body),
    };
    let dialogRef = this.dialog.open(ForcePublishDialogComponent, {
      data: {
        single: true,
        message: "Premendo su 'Ok', confermi la pubblicazione del profilo",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result || result == 0) {
        this.profileService
          .forcePublishProfile(this.user_id, this.hospital_id, request)
          .subscribe({
            next: (res) => {
              console.log(res);
              this._snackBar.open("Profilo pubblicato", "OK");
              this.loading = false;
              this.reload.emit("reload");
            },
            error: (err) => {
              console.error(err);
              this.loading = false;
            },
          });
      } else {
        this.loading = false;
      }
    });
  }

  denyProfile() {
    let request = {
      end_profile_status: null,
      note: null,
    };
    let dialogRef;
    if (this.actions) {
      if (this.actions.deny.length == 1) {
        dialogRef = this.dialog.open(SaveProfileDialogComponent, {
          data: {
            single: true,
            message:
              "Premendo su 'Ok', confermi le modifiche effettuate al profilo",
          },
        });
      } else {
        dialogRef = this.dialog.open(SaveProfileDialogComponent, {
          data: {
            single: false,
            message:
              "Scegli il ruolo che dovrà sistemare il profilo e conferma premendo 'Ok'",
            options: this.actions.deny,
          },
        });
      }
    } else {
      dialogRef = this.dialog.open(SaveProfileDialogComponent, {
        data: {
          single: true,
          message:
            "Premendo su 'Ok', confermi le modifiche effettuate al profilo",
        },
      });
    }
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result || result == 0) {
        if (result == "single") {
          this.profileService
            .denyProfile(this.user_id, this.hospital_id, request)
            .subscribe({
              next: (res) => {
                this._snackBar.open("Profilo rifiutato", "OK");
                this.loading = false;
                this.reload.emit("reload");
              },
              error: (err) => {
                console.error(err);
                this.loading = false;
              },
            });
        } else {
          request.end_profile_status = result.profile_status;
          request.note = result.note;
          this.profileService
            .denyProfile(this.user_id, this.hospital_id, request)
            .subscribe({
              next: (res) => {
                this._snackBar.open("Profilo salvato", "OK");
                this.loading = false;
                this.reload.emit("reload");
              },
              error: (err) => {
                console.error(err);
                this.loading = false;
              },
            });
        }
      } else {
        this.loading = false;
      }
    });
  }

  copyForm(formJSON: string) {
    //sessionStorage.setItem('savedProfile', formJSON);
    this.clipboardService.copyProfile(formJSON);
    this.loading = false;
  }

  getSavedForm() {
    return this.clipboardService.getSavedProfile();
  }

  hasSavedForm() {
    return this.getSavedForm() != "" ? true : false;
  }

  canPasteForm() {
    return this.hasSavedForm() && !this.profileData.readonly;
  }

  pasteForm() {
    this.loading = true;
    let savedForm = JSON.parse(this.getSavedForm());
    Object.keys(savedForm).forEach((element) => {
      let form_control = this.form.get(element.toString());
      form_control?.setValue(savedForm[element]);
    });
    this.loading = false;
  }

  loadDraft() {
    this.loading = true;
    this.profileService.loadDraft(this.draft.id).subscribe({
      next: (res) => {
        res.forEach((element) => {
          if (element.field_id) {
            let form_control = this.form.get(element.field_id.toString());
            form_control?.setValue(element.value);
          } else if (element.field_name) {
            let form_control = this.form.get(element.field_name);
            if (element.field_name == "is_active") {
              form_control?.setValue(+element.value);
            } else {
              form_control?.setValue(element.value);
            }
          }
        });
        this.loading = false;
      },
      error: (err) => {
        console.error(err);
        this.loading = false;
      },
    });
  }
}
