import { Hospital } from "./hospital";

export interface User {
  name: string;
  surname: string;
  user_id: number;
  email: string;
  role: string;
  hospital_list: Hospital[];
}

export class FirebaseUser {
  displayName: string;
  email: string;
  uid: string;
}
