import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { Staff } from "src/app/models/staff";
import { StaffService } from "src/app/services/staff/staff.service";
import { DeleteStaffDialogComponent } from "../fragments/delete-staff-dialog/delete-staff-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-staff",
  templateUrl: "./staff.component.html",
  styleUrls: ["./staff.component.scss"],
})
export class StaffComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  data: Staff[] = [];
  dataSource = new MatTableDataSource<Staff>(this.data);
  filter: string;
  displayedColumns: string[] = [
    "first_name",
    "last_name",
    "email",
    "role_name",
    "delete_action",
  ];

  constructor(
    private staffService: StaffService,
    public router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.loadStaffers();
  }

  loadStaffers(): void {
    this.staffService.getStaff().subscribe({
      next: (data) => {
        this.data = data;
        this._setDatasource();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  private _setDatasource() {
    this.dataSource = new MatTableDataSource<Staff>(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  filterDataSource(filter: string) {
    this.dataSource.filter = filter;
  }

  deleteStaff(staff_id: any) {
    let dialogRef = this.dialog.open(DeleteStaffDialogComponent, {
      data: {
        single: true,
        message: "Premendo su 'Ok', confermi la cancellazione dell'utente",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result || result == 0) {
        this.staffService.deleteStaff(staff_id).subscribe({
          next: (data) => {
            this._snackBar.open("Utente cancellato", "OK");
            this.loadStaffers();
          },
          error: (err) => {
            console.error(err);
          },
        });
      }
    });
  }
}
