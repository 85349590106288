import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "src/app/models/dialog-data";

@Component({
  selector: "app-delete-staff-dialog",
  templateUrl: "./delete-staff-dialog.component.html",
  styleUrls: ["./delete-staff-dialog.component.scss"],
})
export class DeleteStaffDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteStaffDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
