<div>
  <img class="sidenav-title" src="../../../../assets/img/logo.png" />
</div>
<mat-divider></mat-divider>
<mat-nav-list>
  <a mat-list-item routerLink="/"> Dashboard </a>
  <a
    *ngIf="userService.getUserRole() == 'ADMIN'"
    mat-list-item
    routerLink="/staff"
  >
    Staff
  </a>
  <a
    *ngIf="userService.getUserRole() == 'ADMIN'"
    mat-list-item
    routerLink="/statistics">
    Statistiche
  </a>
</mat-nav-list>
