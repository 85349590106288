<h1 mat-dialog-title>Sei sicuro di cancellare questo utente?</h1>
<div mat-dialog-content>
  <div>
    <p>{{ data.message }}</p>
  </div>
</div>
<div mat-dialog-actions class="actions-container">
  <button mat-button (click)="onNoClick()">ANNULLA</button>
  <button mat-button [mat-dialog-close]="'single'" cdkFocusInitial>OK</button>
</div>
