<div *ngIf="hospital_id == 0">
  <form (ngSubmit)="onSubmit($event)" [formGroup]="form">
    <div class="edit-form-buttons" *ngIf="loading">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <div class="edit-form-buttons" *ngIf="!loading">
      <button
        *ngIf="!readonly"
        mat-fab
        color="primary"
        type="submit"
        title="Salva Bozza"
        id="saveDraft"
        [disabled]="!form.valid"
      >
        <mat-icon>save</mat-icon>
      </button>
      <button
        *ngIf="draft && !readonly"
        mat-fab
        title="Carica Bozza"
        color="primary"
        type="button"
        (click)="loadDraft()"
      >
        <mat-icon>file_download</mat-icon>
      </button>
    </div>
    <div class="section-form-container">
      <div *ngFor="let question of questions" class="form-row">
        <app-question
          [question]="question"
          [form]="form"
          [readonly]="readonly"
        ></app-question>
      </div>
    </div>
    <div class="form-buttons" *ngIf="loading">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <div class="form-buttons" *ngIf="!loading">
      <button
        *ngIf="!readonly"
        mat-raised-button
        color="primary"
        type="submit"
        id="approveDraft"
        [disabled]="!form.valid"
      >
        Conferma
      </button>
    </div>
  </form>
</div>
<div *ngIf="hospital_id != 0">
  <div
    *ngIf="(userRole == 'HR' && profileData.user_access) || userRole == 'ADMIN'"
    class="hr-fields-container"
  >
    <h3>Impostazioni HR</h3>
    <div class="hr-form-container">
      <div class="viz-container">
        <div *ngIf="profileData.visibility">
          <button mat-button (click)="switchVisibility()" type="button">
            <mat-icon
              aria-hidden="false"
              aria-label="Example home icon"
              color="primary"
              >visibility</mat-icon
            >
            <span class="viz-text">VISUALIZZAZIONE PROFILO ABILITATA</span>
          </button>
        </div>
        <div *ngIf="!profileData.visibility">
          <button mat-button (click)="switchVisibility()" type="button">
            <mat-icon
              aria-hidden="false"
              aria-label="Example home icon"
              color="primary"
              >visibility_off</mat-icon
            >
            <span class="viz-text">VISUALIZZAZIONE PROFILO DISABILITATA</span>
          </button>
        </div>
      </div>
      <div class="hr-form">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Data inizio contratto</mat-label>
          <mat-hint>DD/MM/YYYY</mat-hint>
          <input
            matInput
            [(ngModel)]="contractStart"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
        <button
          mat-raised-button
          color="primary"
          type="button"
          id="hrFields"
          (click)="saveHrFields()"
          [disabled]="
            contractStart == null ||
            this.loading ||
            !checkDateFormat(contractStart)
          "
        >
          Salva
        </button>
      </div>
    </div>
  </div>
  <form (ngSubmit)="onSubmit($event)" [formGroup]="form">
    <div class="section-form-container">
      <div>
        Stato:
        <mat-chip-option
          [ngClass]="{
            'sap-chip': status == 0,
            'hr-chip': status == 1,
            'doc-chip': status == 2,
            'hr2-chip': status == 3,
            'comm-chip': status == 4,
            'cancelled-chip': status == 6,
            'published-chip': status == 5
          }"
          >{{ metadata.status[status] }}</mat-chip-option
        >
      </div>

      <div class="edit-form-buttons" *ngIf="loading">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
      <div class="edit-form-buttons" *ngIf="!loading">
        <button
          mat-fab
          title="Copia profilo"
          color="primary"
          aria-label="Copia profilo"
          type="submit"
          id="copyForm"
          [disabled]="!form.valid"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
        <button
          *ngIf="canPasteForm()"
          mat-fab
          title="Incolla profilo"
          color="primary"
          type="button"
          id="pasteForm"
          (click)="pasteForm()"
        >
          <mat-icon>content_paste</mat-icon>
        </button>
        <button
          *ngIf="!profileData.readonly"
          mat-fab
          color="primary"
          type="submit"
          title="Salva Bozza"
          id="saveDraft"
          [disabled]="!form.valid"
        >
          <mat-icon>save</mat-icon>
        </button>
        <button
          *ngIf="draft && !profileData.readonly"
          mat-fab
          title="Carica Bozza"
          color="primary"
          type="button"
          (click)="loadDraft()"
        >
          <mat-icon>file_download</mat-icon>
        </button>
      </div>
    </div>

    <div class="section-form-container">
      <mat-accordion *ngFor="let item of profileData.sections">
        <mat-expansion-panel hideToggle expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3>{{ item.title }}</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngFor="let question of item.questions" class="form-row">
            <app-question
              [question]="question"
              [form]="form"
              [readonly]="profileData.readonly"
            ></app-question>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="form-buttons">
      <button
        *ngIf="actions.approve.length > 0"
        mat-raised-button
        color="primary"
        type="submit"
        id="approveDraft"
        [disabled]="!form.valid"
      >
        Conferma e Invia
      </button>
      <button
        *ngIf="actions.deny.length > 0"
        mat-raised-button
        color="primary"
        type="button"
        (click)="denyProfile()"
        id="denyDraft"
      >
        Rifiuta
      </button>
      <button
        *ngIf="actions.forcePublish"
        mat-raised-button
        color="primary"
        type="button"
        type="submit"
        id="forcePublish"
      >
        Forza pubblicazione
      </button>
    </div>
  </form>
</div>
