import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Hospital } from "src/app/models/hospital";
import { UserService } from "src/app/services/user/user.service";

@Component({
  selector: "app-hospital-multiselect",
  templateUrl: "./hospital-multiselect.component.html",
  styleUrls: [
    "./hospital-multiselect.component.scss",
    "../../home/home.component.scss",
  ],
})
export class HospitalMultiselectComponent implements OnInit {
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  hospitalCtrl = new UntypedFormControl();
  filteredHospitals: Observable<Hospital[]>;
  hospitals: Hospital[] = [];
  hospitalList: Hospital[];
  @Output() addHospitalEvent = new EventEmitter<number>();
  @Output() removeHospitalEvent = new EventEmitter<number>();

  @ViewChild("hospitalInput") hospitalInput: ElementRef<HTMLInputElement>;

  constructor(public userService: UserService) {
    this.hospitalList = this.userService.loggedUser().hospital_list;
    this.filteredHospitals = this.hospitalCtrl.valueChanges.pipe(
      startWith(null),
      map((hospitalName: string | null) =>
        hospitalName && typeof hospitalName == "string"
          ? this._filter(hospitalName)
          : this.hospitalList.slice()
      )
    );
  }
  ngOnInit(): void {}

  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    // Add our hospital
    if (value) {
      let addedHospital = this._findHospital(value);
      if (addedHospital) {
        this.hospitals.push(addedHospital);
        // Clear the input value
        event.chipInput!.clear();

        this.hospitalCtrl.setValue(null);
        this.addHospitalEvent.emit(addedHospital.id);
      }
    }
  }

  remove(hospitalId: number): void {
    const index = this.hospitals.map((item) => item.id).indexOf(hospitalId);

    if (index >= 0) {
      this.hospitals.splice(index, 1);
      this.removeHospitalEvent.emit(hospitalId);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.hospitals.push(event.option.value);
    this.hospitalInput.nativeElement.value = "";
    this.hospitalCtrl.setValue(null);
    this.addHospitalEvent.emit(event.option.value.id);
  }

  private _filter(value: string): Hospital[] {
    const filterValue = value.toLowerCase();

    return this.hospitalList.filter((hospital) =>
      hospital.name.toLowerCase().includes(filterValue)
    );
  }

  private _findHospital(value: string): Hospital | undefined {
    let result = this.userService
      .loggedUser()
      .hospital_list.find(
        (element) => element.name.toLowerCase() == value.toLowerCase()
      );
    return result;
  }
}
