import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor() {}

  private savedProfile = new BehaviorSubject<string>('');

  public getSavedProfile() {
    return this.savedProfile.getValue();
  }

  public copyProfile(profile: string) {
    this.savedProfile.next(profile);
  }
}
