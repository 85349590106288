import { Injectable, inject } from "@angular/core";
import { ApiService } from "../api/api.service";
import { FirebaseUser, User } from "../../models/user";
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";
import {
  onAuthStateChanged,
  OAuthProvider,
  Auth,
  signInWithRedirect,
  getAdditionalUserInfo,
  getRedirectResult,
  updateProfile,
  signInWithPopup,
} from "@angular/fire/auth";
import { environment } from "src/environments/environment";
import { FirebaseError } from "@angular/fire/app";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private profileObs$: BehaviorSubject<FirebaseUser | string | null> =
    new BehaviorSubject<FirebaseUser | string | null>(null);

  private provider: OAuthProvider = new OAuthProvider(environment.provider);
  private auth: Auth = inject(Auth);
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
  private user = new BehaviorSubject({
    name: "",
    surname: "",
    user_id: 0,
    email: "",
    role: "",
    hospital_list: [],
  } as User);

  public loggedUser(): User {
    return this.user.getValue();
  }

  public getUserRole(): string {
    return this.user.getValue().role;
  }

  constructor(private apiService: ApiService) {
    onAuthStateChanged(
      this.auth,
      (firebaseUser) => {
        if (firebaseUser) {
          let user: FirebaseUser;

          user = new FirebaseUser();
          user.displayName = firebaseUser.displayName!;
          user.email = firebaseUser.email!;
          user.uid = firebaseUser.uid!;
          this.profileObs$.next(user);

          //this.logout();
        } else {
          this.login();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public async getInfoFromBackend() {
    let user = await lastValueFrom(this.apiService.login());
    this.user.next(user);
    this.isAuthenticatedSubject$.next(true);
  }

  public login() {
    signInWithPopup(this.auth, this.provider).then((res) => {
      console.log(res);
      let firebaseUser = res.user;
      let user: FirebaseUser;

      user = new FirebaseUser();
      user.displayName = firebaseUser.displayName!;
      user.email = firebaseUser.email!;
      user.uid = firebaseUser.uid!;
      this.profileObs$.next(user);
    });
    // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // const isFirefox = navigator.userAgent.toLowerCase().includes("firefox");
    // if (isSafari || isFirefox) {
    //
    // } else {
    //   signInWithRedirect(this.auth, this.provider);
    // }
  }

  public logout() {
    this.auth.signOut();
  }

  public async getToken() {
    return await this.auth.currentUser?.getIdToken();
  }

  public getCurrentUser(): Observable<FirebaseUser | string | null> {
    return this.profileObs$.asObservable();
  }
}
