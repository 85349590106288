import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FirebaseUser, User } from "./models/user";
import { MetadataService } from "./services/metadata/metadata.service";
import { UserService } from "./services/user/user.service";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "humanitas-cv-fe";
  user: User | null = null;
  unauth: boolean = false;

  constructor(
    public userService: UserService,
    public metadataService: MetadataService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.checkAuth();
  }

  checkAuth() {
    let self = this;
    this.userService
      .getCurrentUser()
      .pipe(
        takeWhile((value) => {
          return !(value instanceof FirebaseUser);
        }, true)
      )
      .subscribe({
        next: (user) => this.userFlow(user, self),
      });
  }

  userFlow(user: string | FirebaseUser | null, self: AppComponent) {
    if (user) {
      self.userService.getInfoFromBackend().then(() => {
        self.user = self.userService.loggedUser();
        self.unauth = false;
        self.changeDetectorRef.detectChanges();
      });
      self.metadataService.getMetadataFromBackend();
    }
  }

  getInfo() {
    console.log("getinfo");
  }
}
