import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from 'src/app/components/fragments/error-dialog/error-dialog.component';
import { CVError } from 'src/app/models/cv-error';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {
  errors: { [key: string]: string } = {
    '0000': "Errore nell'autenticazione IAP",
    '0001': 'Errore di autenticazione',
    '0002': "L'utente non ha un ruolo valido",
    '0003': 'Utente non registrato',
    '1000': 'Non hai permessi per accedere a questo profilo',
    '2000': 'Servizio disponibile solo tramite Cloud Task',
    '3000': 'Foto mancante o con un tipo di file non ammesso',
    default:
      'Errore di sistema; contatta gli amministratori o riprova più tardi',
  };
  constructor(public dialog: MatDialog) {}

  public showError(error: CVError | string) {
    let errorMessage = this.errors.default;
    if (this.isError(error) && 'code' in error) {
      errorMessage = this.errors[error.code];
    }
    let dialogRef = this.dialog.open(ErrorDialogComponent, {
      data: { message: errorMessage },
    });
  }

  private isError(error: CVError | string): error is CVError {
    return (error as CVError).code !== undefined;
  }
}
