import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { UntypedFormGroup } from "@angular/forms";

import { QuestionBase } from "src/app/models/question/question-base";
import { QuestionControlService } from "src/app/services/question-control/question-control.service";
import { UserService } from "src/app/services/user/user.service";
import { ProfileResponse } from "src/app/models/profile-response";
import { ProfileService } from "src/app/services/profile/profile.service";
import { MetadataService } from "src/app/services/metadata/metadata.service";
import { Metadata } from "src/app/models/metadata";
import { User } from "src/app/models/user";
import { MatDialog } from "@angular/material/dialog";
import { SaveProfileDialogComponent } from "../fragments/save-profile-dialog/save-profile-dialog.component";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
  providers: [QuestionControlService],
})
export class ProfileComponent implements OnInit {
  profile_id: string;
  questions: { [key: string]: QuestionBase<string>[] } | null = {};
  forms: { [key: number]: UntypedFormGroup } = {};
  form!: UntypedFormGroup;
  payLoad = "";
  profileResponse: ProfileResponse | null = null;
  metadata: Metadata;
  user: User;

  constructor(
    public userService: UserService,
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    public metadataService: MetadataService,
    public dialog: MatDialog,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.user = this.userService.loggedUser();
    this.route.queryParams.subscribe((params) => {
      this.profile_id = params["n"];
      this.profileService.getProfile(this.profile_id).subscribe({
        next: (data) => {
          this.profileResponse = data;
        },
        error: (err) => {
          this.router.navigate(["/"]);
        },
      });
      // this.metadata = this.metadataService.getMetadata();
    });
    //this.form.disable();
  }

  onSubmit(form_index: number) {
    this.payLoad = JSON.stringify(this.forms[form_index].getRawValue());
    this.profileService
      .saveProfile(this.profile_id, form_index, this.payLoad)
      .subscribe({
        next: (res) => {
          console.log(res);
        },
        error: (err) => {
          console.log("profile error: " + err);
          this.router.navigate(["/"]);
        },
      });
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SaveProfileDialogComponent, {
      width: "250px",
      data: { name: "test", animal: "test" },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed", result);
    });
  }

  reload(reload: string) {
    console.log("reload");
    this.ngOnInit();
  }

  imageInputChange(fileInputEvent: any) {
    let selectedPhoto = fileInputEvent.target.files[0];
    console.log(selectedPhoto);
    let formData = new FormData();
    formData.append("photo", selectedPhoto);
    formData.forEach((element) => {
      console.log(element);
    });
    this.profileService
      .saveProfilePhoto(this.profile_id, formData)
      .subscribe((response) => {
        console.log(response);
        this.reload("true");
      });
  }

  private checkDisableForm(
    key: number,
    profile_status: { [key: number]: number }
  ): boolean {
    if (this.user.role == "HR") {
      if (profile_status[key] == 2 || profile_status[key] == 3) {
        return true;
      }
    } else if (this.user.role == "DOC") {
      if (profile_status[key] != 2) {
        return true;
      }
    } else if (this.user.role == "DMS") {
      return true;
    }
    return false;
  }
}
