import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TableDoctor } from "src/app/models/table-doctor";

@Component({
  selector: "app-dashboard-doctor-table",
  templateUrl: "./dashboard-doctor-table.component.html",
  styleUrls: ["./dashboard-doctor-table.component.scss"],
})
export class DashboardDoctorTableComponent implements OnInit, OnChanges {
  @Input() filter: string = "";
  @Input() ELEMENT_DATA: TableDoctor[];
  displayedColumns: string[] = ["name", "surname", "fiscal_code", "status"];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<TableDoctor>;
  notificationsFilter: boolean[] = [];
  hospitalFilter: number[] = [];

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if ("ELEMENT_DATA" in changes) {
      this.dataSource = new MatTableDataSource<TableDoctor>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    if ("filter" in changes) {
      this.dataSource.filter = this.filter;
    }
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<TableDoctor>(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
