<div>
  <table mat-table [dataSource]="dataSource" matSort>
    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <!-- surname Column -->
    <ng-container matColumnDef="surname">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Surname</th>
      <td mat-cell *matCellDef="let element">{{ element.surname }}</td>
    </ng-container>

    <ng-container matColumnDef="fiscal_code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Fiscal Code</th>
      <td mat-cell *matCellDef="let element">{{ element.fiscal_code }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element">
        <mat-chip-listbox aria-label="Fish selection">
          <mat-chip-option
            *ngFor="let item of element.status | keyvalue"
            [ngClass]="{
              'sap-chip': item.value == 0,
              'hr-chip': item.value == 1,
              'doc-chip': item.value == 2,
              'hr2-chip': item.value == 3,
              'comm-chip': item.value == 4,
              'published-chip': item.value == 5,
              'cancelled-chip': item.value == 6
            }"
            >{{ item.key }}</mat-chip-option
          >
        </mat-chip-listbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    >
    <tr
      [routerLink]="['/profile']"
      [queryParams]="{ n: row.id }"
      routerLinkActive="active"
      mat-row
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[10, 20]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>
