<mat-form-field class="example-chip-list">
  <mat-label>Seleziona ospedali</mat-label>
  <mat-chip-grid #chipList aria-label="Selezione ospedale">
    <mat-chip-row
      *ngFor="let hospital of hospitals"
      [removable]="removable"
      (removed)="remove(hospital.id)"
      class="chip-format"
    >
      {{ hospital.name }}
      <button matChipRemove *ngIf="removable">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input
      placeholder="Scegli ospedale"
      #hospitalInput
      [formControl]="hospitalCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option
      *ngFor="let hospital of filteredHospitals | async"
      [value]="hospital"
    >
      {{ hospital.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
