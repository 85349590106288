import { Injectable } from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";

import { QuestionBase } from "../../models/question/question-base";

@Injectable({
  providedIn: "root",
})
export class QuestionControlService {
  constructor() {}

  htmlMaxLength(value: string): number {
    let regex = /(<([^>]+)>)/gi;
    let result: string = value.replace(regex, "");
    return result.length;
  }

  htmlMaxLengthValidator(control: AbstractControl): ValidationErrors | null {
    let value = control.value;
    if (value != null) {
      let regex = /(<([^>]+)>)/gi;
      let result: string = value.replace(regex, "");
      return result.length > 1000
        ? {
            htmlmaxlength: {
              actualLength: result.length,
              requiredLength: 1000,
            },
          }
        : null;
    } else return null;
  }

  toFormGroup(questions: QuestionBase<string>[]) {
    const group: any = {};

    questions.forEach((question) => {
      if (question.controlType == "email") {
        group[question.key + "value"] = question.required
          ? new UntypedFormControl(
              this.getValue(question.value?.split("@")[0]),
              [Validators.required, Validators.maxLength(1000)]
            )
          : new UntypedFormControl(
              this.getValue(question.value?.split("@")[0])
            );
        group[question.key + "domain"] = question.required
          ? new UntypedFormControl(
              this.getValue("@" + question.value?.split("@")[1]),
              [Validators.required, Validators.maxLength(1000)]
            )
          : new UntypedFormControl(
              this.getValue("@" + question.value?.split("@")[1])
            );
      } else if (question.controlType == "textbox") {
        group[question.key] = question.required
          ? new UntypedFormControl(this.getValue(question.value), [
              Validators.required,
              this.htmlMaxLengthValidator,
            ])
          : new UntypedFormControl(this.getValue(question.value), [
              this.htmlMaxLengthValidator,
            ]);
      } else {
        group[question.key] = question.required
          ? new UntypedFormControl(this.getValue(question.value), [
              Validators.required,
              Validators.maxLength(1000),
            ])
          : new UntypedFormControl(this.getValue(question.value), [
              Validators.maxLength(1000),
            ]);
      }
    });
    return new UntypedFormGroup(group);
  }

  private getValue(value: string | undefined): any {
    if (value == undefined) {
      return "";
    } else {
      return value;
    }
  }
}
