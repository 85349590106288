import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MetadataService } from "src/app/services/metadata/metadata.service";
import { ProfileService } from "src/app/services/profile/profile.service";

@Component({
  selector: "app-hospital-form",
  templateUrl: "./hospital-form.component.html",
  styleUrls: [
    "./hospital-form.component.scss",
    "../profile-form/profile-form.component.scss",
  ],
})
export class HospitalFormComponent implements OnInit {
  loading: boolean = false;
  form!: UntypedFormGroup;
  @Input() user_hospitals: any;
  @Input() profile_id: string;
  @Output() reload = new EventEmitter<string>();

  constructor(
    public metadataService: MetadataService,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.form = this.toFormGroup(this.metadataService.getMetadata().hospitals);
  }

  onSubmit(event: SubmitEvent) {
    this.loading = true;
    let payload = JSON.stringify(this.form.getRawValue());
    let self = this;
    this.profileService
      .saveProfileHospitals(this.profile_id, payload)
      .subscribe({
        next(obj) {
          self.loading = false;
          self.reload.emit("reload");
        },
        error(e) {
          self.loading = false;
        },
      });
  }
  formatFormValues(values: any) {
    if ("emailvalue" in values && "emaildomain" in values) {
      values["email"] = values["emailvalue"] + values["emaildomain"];
      delete values["emailvalue"];
      delete values["emaildomain"];
    }
    return values;
  }

  toFormGroup(hospitals: { [key: string]: string }) {
    const group: any = {};
    for (let h in hospitals) {
      group[h] = new UntypedFormControl(
        h in this.user_hospitals && this.user_hospitals[h] < 6
      );
    }
    return new UntypedFormGroup(group);
  }
}
