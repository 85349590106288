import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DashboardNotification } from 'src/app/models/dashboard-notification';

@Component({
  selector: 'app-notifications-section',
  templateUrl: './notifications-section.component.html',
  styleUrls: ['./notifications-section.component.scss'],
})
export class NotificationsSectionComponent implements OnInit {
  centered = false;
  disabled = false;
  unbounded = false;
  @Input() notifications: DashboardNotification[];
  @Input() notificationsFilter: boolean[];
  @Output() applyNotificationFilter = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  toggleNotificationFilter(
    index: number,
    notification: DashboardNotification
  ): void {
    this.applyNotificationFilter.emit({
      index: index,
      notification: notification,
    });
  }
}
