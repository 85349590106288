<div class="mat-elevation-z8" style="background-color: white">
  <div class="table-banner">
    <h3>Elenco professionisti</h3>
    <mat-divider></mat-divider>
    <form class="home-filter-form">
      <mat-accordion>
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title><h6>Filtri</h6></mat-panel-title>
          </mat-expansion-panel-header>
          <app-hospital-multiselect
            (addHospitalEvent)="addHospitalFilter($event)"
            (removeHospitalEvent)="removeHospitalFilter($event)"
          ></app-hospital-multiselect>
          <mat-form-field class="home-filter-form-field">
            <mat-label>Filtro</mat-label>
            <input [(ngModel)]="filter" name="filter" matInput />
          </mat-form-field>
        </mat-expansion-panel>
      </mat-accordion>
    </form>
  </div>
  <div>
    <app-dashboard-doctor-table
      [ELEMENT_DATA]="data"
      [filter]="filter"
    ></app-dashboard-doctor-table>
  </div>
  <div class="table-banner">
    <form class="home-filter-form">
      <mat-accordion>
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title><h6>Download anagrafica</h6></mat-panel-title>
          </mat-expansion-panel-header>
          <app-hospital-multiselect
            (addHospitalEvent)="addHospitalDownload($event)"
            (removeHospitalEvent)="removeHospitalDownload($event)"
          ></app-hospital-multiselect>
          <div>
            <div *ngIf="loading">
              <mat-spinner [diameter]="50"></mat-spinner>
            </div>
            <div *ngIf="!loading">
              <button
                mat-button
                (click)="downloadAnag()"
                mat-raised-button
                color="primary"
                type="button"
                id="downloadAnag"
              >
                Download
              </button>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </form>
  </div>
</div>
<div class="status-key-container">
  <app-status-key></app-status-key>
</div>
