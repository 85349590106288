import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class HtmlLengthService {
  constructor() {}

  htmlMaxLength(value: string): number {
    if (value == null) {
      return 0;
    }
    let regex = /(<([^>]+)>)/gi;
    let result: string = value.replace(regex, "");
    return result.length;
  }
}
