import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Staff } from "src/app/models/staff";
import { StaffInfo } from "src/app/models/staff-info";
import { ApiService } from "../api/api.service";

@Injectable({
  providedIn: "root",
})
export class StaffService {
  constructor(private apiService: ApiService) {}

  public getStaff(): Observable<Staff[]> {
    return this.apiService.getStaff();
  }

  public getStaffInfo(id: number): Observable<StaffInfo> {
    return this.apiService.getStaffInfo(id);
  }

  public createStaff(request: any): Observable<StaffInfo> {
    return this.apiService.createStaff(request);
  }

  public updateStaffInfo(id: number, request: any): Observable<StaffInfo> {
    return this.apiService.updateStaffInfo(id, request);
  }

  public deleteStaff(id: number): Observable<any> {
    return this.apiService.deleteStaff(id);
  }
}
