<div class="staff-info-container">
  <div class="mat-elevation-z8 staff-card" *ngIf="!staffInfoId || !loading">
    <h2>Informazioni utente</h2>
    <form
      [formGroup]="staffInfoForm"
      (ngSubmit)="onSubmit()"
      class="staff-info-form"
    >
      <div class="staff-info-form-row">
        <mat-form-field class="staff-form-item">
          <mat-label>Nome</mat-label>
          <input
            matInput
            placeholder="Nome dell'utente"
            formControlName="firstName"
            required
          />
        </mat-form-field>
      </div>
      <div class="staff-info-form-row">
        <mat-form-field class="staff-form-item">
          <mat-label>Cognome</mat-label>
          <input
            matInput
            placeholder="Nome dell'utente"
            formControlName="lastName"
            required
          />
        </mat-form-field>
      </div>
      <div class="staff-info-form-row">
        <mat-form-field class="staff-form-item">
          <mat-label>Email</mat-label>
          <input
            matInput
            placeholder="Email dell'utente"
            formControlName="email"
            required
          />
        </mat-form-field>
      </div>
      <div class="staff-info-form-row">
        <mat-form-field class="staff-form-item">
          <mat-label>Ruolo</mat-label>
          <mat-select formControlName="role" required>
            <mat-option *ngFor="let role of roles" [value]="role.id">
              {{ role.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="staff-info-form-row">
        <div class="hospital-list">
          <h3>Ospedali:</h3>
          <mat-list role="list">
            <mat-list-item
              role="listitem"
              *ngFor="let hospital of hospitalList"
            >
              <span matLine>{{ hospital.name }}</span>
              <button mat-icon-button (click)="removeHospital(hospital.id)">
                <mat-icon>delete</mat-icon>
              </button></mat-list-item
            >
          </mat-list>
          <div>
            <button
              mat-icon-button
              type="button"
              (click)="showHospitalSelect()"
            >
              <mat-icon>add</mat-icon>
            </button>
            <mat-form-field *ngIf="hospitalSelect">
              <mat-label>Ospedali</mat-label>
              <mat-select [(value)]="hospitalToAdd">
                <mat-option
                  *ngFor="let hospital of getHospitalToAdd()"
                  [value]="hospital"
                  (onSelectionChange)="addHospital(hospital)"
                >
                  {{ hospital.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="staff-info-form-row button-row">
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="!staffInfoForm.valid"
        >
          CONFERMA
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="staffInfoId && loading">
    <mat-spinner></mat-spinner>
  </div>
</div>
