import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError, from, switchMap } from "rxjs";
import { catchError } from "rxjs/operators";
import { ErrorsService } from "../services/errors/errors.service";
import { Router } from "@angular/router";
import { UserService } from "../services/user/user.service";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    public router: Router,
    public errorService: ErrorsService,
    public userService: UserService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Get the auth token from the service.
    return from(this.userService.getToken()).pipe(
      switchMap((token) => {
        // Clone the request and replace the original headers with
        // cloned headers, updated with the authorization.
        const authReq = req.clone({
          headers: req.headers.set("Authorization", "Bearer " + token!),
        });
        return next.handle(authReq).pipe(
          catchError((error) => {
            this.errorService.showError(error.error);
            return throwError(() => error);
          })
        );
      })
    );
  }
}
