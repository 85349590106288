<h1 mat-dialog-title class="error-dialog-title">
  <mat-icon color="accent">warning</mat-icon>Si è verificato un problema
</h1>
<div mat-dialog-content>
  <div>
    <p>{{ data.message }}</p>
  </div>
</div>

<div mat-dialog-actions class="actions-container">
  <button mat-button (click)="onNoClick()" cdkFocusInitial>OK</button>
</div>
