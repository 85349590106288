import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MatChipsModule } from "@angular/material/chips";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRippleModule } from "@angular/material/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/fragments/header/header.component";
import { FooterComponent } from "./components/fragments/footer/footer.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HomeComponent } from "./components/home/home.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { UserService } from "./services/user/user.service";
import { SidenavComponent } from "./components/fragments/sidenav/sidenav.component";
import { DynamicFormComponent } from "./components/fragments/dynamic-form/dynamic-form.component";
import { HomeHrComponent } from "./components/fragments/home-hr/home-hr.component";
import { HomeDoctorComponent } from "./components/fragments/home-doctor/home-doctor.component";
import { HomeDmsComponent } from "./components/fragments/home-dms/home-dms.component";
import { HomeCommComponent } from "./components/fragments/home-comm/home-comm.component";
import { SaveProfileDialogComponent } from "./components/fragments/save-profile-dialog/save-profile-dialog.component";
import { ForcePublishDialogComponent } from "./components/fragments/force-publish-dialog/force-publish-dialog.component";
import { ProfileFormComponent } from "./components/fragments/profile-form/profile-form.component";
import { ErrorDialogComponent } from "./components/fragments/error-dialog/error-dialog.component";
import { HttpInterceptorService } from "./interceptors/http-interceptor.service";
import { StatusKeyComponent } from "./components/fragments/status-key/status-key.component";
import { HospitalsComponent } from "./components/hospitals/hospitals.component";
import { HomeAdminComponent } from "./components/fragments/home-admin/home-admin.component";
import { HospitalMultiselectComponent } from "./components/fragments/hospital-multiselect/hospital-multiselect.component";
import { StaffComponent } from "./components/staff/staff.component";
import { DashboardDoctorTableComponent } from "./components/fragments/dashboard-doctor-table/dashboard-doctor-table.component";
import { DashboardTableSectionComponent } from "./components/fragments/dashboard-table-section/dashboard-table-section.component";
import { NotificationsSectionComponent } from "./components/fragments/notifications-section/notifications-section.component";
import { StaffInfoComponent } from "./components/staff-info/staff-info.component";
import { UnauthorizedComponent } from "./components/unauthorized/unauthorized.component";
import { RteEditorComponent } from "./components/fragments/rte-editor/rte-editor.component";
import { environment } from "src/environments/environment";

import { provideFirebaseApp, initializeApp } from "@angular/fire/app";
import { getAuth, provideAuth } from "@angular/fire/auth";
import { HospitalFormComponent } from "./components/fragments/hospital-form/hospital-form.component";

import { NgxEditorModule } from "ngx-editor";
import { DeleteStaffDialogComponent } from "./components/fragments/delete-staff-dialog/delete-staff-dialog.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ProfileComponent,
    SidenavComponent,
    DynamicFormComponent,
    HomeHrComponent,
    HomeDoctorComponent,
    HomeDmsComponent,
    HomeCommComponent,
    SaveProfileDialogComponent,
    ForcePublishDialogComponent,
    DeleteStaffDialogComponent,
    ProfileFormComponent,
    ErrorDialogComponent,
    StatusKeyComponent,
    HospitalsComponent,
    HomeAdminComponent,
    HospitalMultiselectComponent,
    StaffComponent,
    DashboardDoctorTableComponent,
    DashboardTableSectionComponent,
    NotificationsSectionComponent,
    StaffInfoComponent,
    UnauthorizedComponent,
    HospitalFormComponent,
    RteEditorComponent,
  ],
  imports: [
    NgxEditorModule,
    MatListModule,
    MatDividerModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatChipsModule,
    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTabsModule,
    MatTooltipModule,
    MatExpansionModule,
    MatDialogModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
  ],
  providers: [
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
