<form (ngSubmit)="onSubmit($event)" [formGroup]="form">
  <div class="section-form-container">
    <div
      *ngFor="let h of metadataService.getMetadata().hospitals | keyvalue"
      class="form-row"
    >
      <mat-checkbox [formControlName]="h.key" class="example-margin">{{
        h.value
      }}</mat-checkbox>
    </div>
  </div>
  <div class="form-buttons" *ngIf="loading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <div class="form-buttons" *ngIf="!loading">
    <button
      mat-raised-button
      color="primary"
      type="submit"
      id="approveDraft"
      [disabled]="!form.valid"
    >
      Conferma
    </button>
  </div>
</form>
