import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "src/app/models/dialog-data";

@Component({
  selector: "app-force-publish-dialog",
  templateUrl: "./force-publish-dialog.component.html",
  styleUrls: ["./force-publish-dialog.component.scss"],
})
export class ForcePublishDialogComponent {
  end_profile_status_id: number;
  note: string;

  constructor(
    public dialogRef: MatDialogRef<ForcePublishDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
