import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { User } from "../../models/user";
import { ProfileResponse } from "../../models/profile-response";
import { Dashboard } from "src/app/models/dashboard";
import { Metadata } from "src/app/models/metadata";
import { DraftContent } from "src/app/models/draft-content";
import { Staff } from "src/app/models/staff";
import { StaffInfo } from "src/app/models/staff-info";
import { Visibility } from "src/app/models/visibility";
import { InfoCard } from "src/app/models/InfoCard";
import { AnagDownload } from "src/app/models/anag-download";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient) {}

  public login(): Observable<any> {
    return this.http.get<User>(environment.backendUrl + "/login");
  }

  public getProfile(user_id: string): Observable<ProfileResponse> {
    return this.http.get<ProfileResponse>(
      environment.backendUrl + "/profile/" + user_id
    );
  }

  public getDashboard(): Observable<Dashboard> {
    return this.http.get<Dashboard>(environment.backendUrl + "/dashboard/");
  }

  public getDownloadAnag(hospitalIds: number[]): Observable<AnagDownload> {
    return this.http.get<AnagDownload>(
      environment.backendUrl + "/dashboard/download",
      { params: { id: hospitalIds } }
    );
  }

  public getMetadata(): Observable<Metadata> {
    return this.http.get<Metadata>(environment.backendUrl + "/metadata/");
  }

  public getChartsData(
    startDate?: string,
    endDate?: string
  ): Observable<InfoCard> {
    let params: { [key: string]: string } = {};
    if (startDate !== undefined && endDate !== undefined) {
      params["startDate"] = startDate;
      params["endDate"] = endDate;
    }
    return this.http.get<InfoCard>(environment.backendUrl + "/kpi/", {
      params: params,
    });
  }

  public getChartsDataByHospital(
    hospital_id: number,
    startDate?: string,
    endDate?: string
  ): Observable<InfoCard> {
    let params: { [key: string]: string } = {};
    if (startDate !== undefined && endDate !== undefined) {
      params["startDate"] = startDate;
      params["endDate"] = endDate;
    }
    return this.http.get<InfoCard>(
      environment.backendUrl + "/kpi/" + hospital_id,
      {
        params: params,
      }
    );
  }

  public saveProfileHospitals(user_id: string, payload: any): Observable<any> {
    return this.http.post(
      environment.backendUrl + "/profile/" + user_id + "/hospitals/save",
      payload,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      }
    );
  }

  public saveProfile(
    user_id: string,
    hospital_id: number,
    payload: any
  ): Observable<any> {
    return this.http.post(
      environment.backendUrl + "/profile/" + user_id + "/save/" + hospital_id,
      payload,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      }
    );
  }

  public forcePublishProfile(
    user_id: string,
    hospital_id: number,
    payload: any
  ): Observable<any> {
    return this.http.post(
      environment.backendUrl +
        "/profile/" +
        user_id +
        "/publish/" +
        hospital_id,
      payload,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      }
    );
  }

  public approveProfile(
    user_id: string,
    hospital_id: number,
    payload: any
  ): Observable<any> {
    return this.http.post(
      environment.backendUrl +
        "/profile/" +
        user_id +
        "/approve/" +
        hospital_id,
      payload,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      }
    );
  }

  public denyProfile(
    user_id: string,
    hospital_id: number,
    payload: any
  ): Observable<any> {
    return this.http.post(
      environment.backendUrl + "/profile/" + user_id + "/deny/" + hospital_id,
      payload,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      }
    );
  }

  public loadDraft(draft_id: number): Observable<DraftContent[]> {
    return this.http.get<DraftContent[]>(
      environment.backendUrl + "/profile/draft/" + draft_id
    );
  }

  public savePhoto(user_id: string, body: FormData): Observable<any> {
    return this.http.post<any>(
      environment.backendUrl + "/profile/" + user_id + "/photo",
      body
    );
  }

  public getStaff(): Observable<Staff[]> {
    return this.http.get<Staff[]>(environment.backendUrl + "/staff/");
  }

  public getStaffInfo(id: number): Observable<StaffInfo> {
    return this.http.get<StaffInfo>(environment.backendUrl + "/staff/" + id);
  }

  public createStaff(request: any): Observable<StaffInfo> {
    return this.http.post<StaffInfo>(
      environment.backendUrl + "/staff/",
      request
    );
  }

  public updateStaffInfo(id: number, request: any): Observable<StaffInfo> {
    return this.http.put<StaffInfo>(
      environment.backendUrl + "/staff/" + id,
      request
    );
  }

  public deleteStaff(id: number): Observable<any> {
    return this.http.delete<any>(environment.backendUrl + "/staff/" + id);
  }

  public switchVisibility(
    user_id: string,
    hospital_id: number
  ): Observable<Visibility> {
    return this.http.get<Visibility>(
      environment.backendUrl +
        "/profile/visibility/" +
        hospital_id +
        "/" +
        user_id
    );
  }

  public setHrFields(
    hospital_id: number,
    user_id: string,
    startContract: string | null
  ): Observable<any> {
    return this.http.put<any>(
      environment.backendUrl +
        "/profile/hr-fields/" +
        hospital_id +
        "/" +
        user_id,
      { contract_start: startContract }
    );
  }
}
