import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Hospital } from "src/app/models/hospital";
import { StaffService } from "src/app/services/staff/staff.service";
import { UserService } from "src/app/services/user/user.service";

@Component({
  selector: "app-staff-info",
  templateUrl: "./staff-info.component.html",
  styleUrls: ["./staff-info.component.scss"],
})
export class StaffInfoComponent implements OnInit {
  hospitalSelect: boolean = false;
  staffInfoId: number | null = null;
  loading: boolean = true;
  hospitalList: Hospital[] = [];
  staffInfoForm = new UntypedFormGroup({
    firstName: new UntypedFormControl("", [Validators.minLength(1)]),
    lastName: new UntypedFormControl("", [Validators.minLength(1)]),
    email: new UntypedFormControl("", [Validators.email]),
    role: new UntypedFormControl(""),
  });
  hospitalToAdd: Hospital | null;
  roles = [
    { id: "hr", name: "HR" },
    { id: "dms", name: "DMS" },
    { id: "admin", name: "ADMIN" },
    { id: "comm", name: "COMM" },
  ];

  constructor(
    public userService: UserService,
    private staffService: StaffService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.staffInfoId = params["n"];
      if (this.staffInfoId) {
        this.staffService.getStaffInfo(this.staffInfoId).subscribe({
          next: (data) => {
            this.staffInfoForm.setValue({
              firstName: data.first_name,
              lastName: data.last_name,
              email: data.email,
              role: data.role_id,
              // role_id
              // role_name: string;
              // hospital_list: Hospital[];
            });
            this.hospitalList = data.hospital_list;
            this.loading = false;
          },
        });
      }
    });
  }

  onSubmit() {
    let request = this.staffInfoForm.value;
    request["hospital_list"] = this.hospitalList;
    if (this.staffInfoId) {
      this.staffService.updateStaffInfo(this.staffInfoId, request).subscribe({
        next: (data) => {
          this.router.navigate(["/staff"]);
        },
      });
    } else {
      this.staffService.createStaff(request).subscribe({
        next: (data) => {
          this.router.navigate(["/staff"]);
        },
      });
    }
  }

  removeHospital(hospitalId: number) {
    const index = this.hospitalList
      .map((hospital) => hospital.id)
      .indexOf(hospitalId);

    if (index >= 0) {
      this.hospitalList.splice(index, 1);
    }
    this.hospitalToAdd = null;
  }

  getHospitalToAdd(): Hospital[] {
    let self = this;
    let result = this.userService
      .loggedUser()
      .hospital_list.filter(function (item) {
        return (
          self.hospitalList.map((hospital) => hospital.id).indexOf(item.id) ===
          -1
        );
      });
    return result;
  }

  addHospital(hospital: Hospital) {
    this.hospitalList.push(hospital);
    this.hospitalToAdd = null;
    this.hospitalSelect = false;
  }

  showHospitalSelect() {
    this.hospitalSelect = !this.hospitalSelect;
    this.hospitalToAdd = null;
  }
}
