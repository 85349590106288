<div class="unauth-container">
  <div class="mat-elevation-z8 unauth-card">
    <div class="unauth-icon">
      <mat-icon
        style="font-size: 50px"
        color="accent"
        aria-hidden="false"
        aria-label="Example home icon"
        >warning</mat-icon
      >
    </div>
    <div class="unauth-message">
      Non sei autorizzato ad accedere a questa pagina, contatta gli
      amministratori per venir autorizzato
    </div>
  </div>
</div>
