import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/services/user/user.service";
import { TableDoctor } from "src/app/models/table-doctor";
import { DashboardService } from "src/app/services/dashboard/dashboard.service";
import { Dashboard } from "src/app/models/dashboard";
import { DashboardNotification } from "src/app/models/dashboard-notification";

@Component({
  selector: "app-home-comm",
  templateUrl: "./home-comm.component.html",
  styleUrls: ["./home-comm.component.scss", "../../home/home.component.scss"],
})
export class HomeCommComponent implements OnInit {
  notifTest: string = "all";
  dashboard: Dashboard;
  ELEMENT_DATA: TableDoctor[] = [];
  notifications: DashboardNotification[] = [];
  notificationsFilter: boolean[] = [];
  hospitalFilter: number[] = [];
  data: TableDoctor[] = [];

  constructor(
    public userService: UserService,
    private route: Router,
    private router: ActivatedRoute,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.dashboardService.getDashboard().subscribe({
      next: (data) => {
        this.dashboard = data;
        this.notifications = this.dashboard.notifications;
        this.notifications.forEach((not) => {
          this.notificationsFilter.push(false);
        });
        this.ELEMENT_DATA = this.dashboard.doctors;
        this.data = this.ELEMENT_DATA;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  ngAfterViewInit() {
    //this.dataSource.filter = 'Helium';
  }

  toggleNotificationFilter(data: any): void {
    let index = data.index;
    let notification = data.notification;
    for (var k in this.notificationsFilter) {
      if (+k != index) {
        this.notificationsFilter[+k] = false;
      }
    }
    this.notificationsFilter[index] = !this.notificationsFilter[index];
    if (this.notificationsFilter[index]) {
      this._setDatasource(notification);
    } else {
      this._setDatasource();
    }
  }

  private _setDatasource(notification?: DashboardNotification) {
    let data = this.ELEMENT_DATA;
    let index = -1;
    if (notification) {
      data = this.filterDashboard(notification);
    } else {
      for (let i in this.notificationsFilter) {
        if (this.notificationsFilter[+i]) {
          index = +i;
        }
      }
      if (index > 0) {
        data = this.filterDashboard(this.notifications[index]);
      }
    }
    this.data = this.filterByHospitals(data);
  }

  globalFilter() {
    const myFilterPredicate = (data: TableDoctor, filter: string): boolean => {
      let globalString =
        data.id.toString().trim().toLowerCase() +
        data.name.toString().trim().toLowerCase() +
        data.surname.toString().trim().toLowerCase();
      if (this.notifTest == "hr") {
        let status = data.status;
        for (const key in status) {
          if (status[key] == 1) {
            return globalString.includes(filter);
          }
        }
        return false;
      } else {
        return globalString.includes(filter);
      }
    };
    return myFilterPredicate;
  }

  filterDashboard(notification: DashboardNotification): TableDoctor[] {
    return this.dashboardService.filterDashboard(this.dashboard, notification);
  }

  setHospitalFilter(hospitalList: number[]) {
    this.hospitalFilter = hospitalList;
    this._setDatasource();
  }

  filterByHospitals(data: TableDoctor[]): TableDoctor[] {
    let hospitalList = this.userService.loggedUser().hospital_list;
    let hospitalObj: { [key: string]: number } = {};
    hospitalList.forEach((elem) => {
      hospitalObj[elem.name] = elem.id;
    });
    if (this.hospitalFilter.length > 0) {
      return data.filter((row) =>
        Object.keys(row.status).some((hospitalName) =>
          this.hospitalFilter.includes(hospitalObj[hospitalName])
        )
      );
    } else {
      return data;
    }
  }
}
