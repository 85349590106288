import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { QuestionBase } from "src/app/models/question/question-base";
import { HtmlLengthService } from "src/app/services/html-length/html-length.service";
import { MetadataService } from "src/app/services/metadata/metadata.service";

@Component({
  selector: "app-question",
  templateUrl: "./dynamic-form.component.html",
  styleUrls: ["./dynamic-form.component.scss"],
})
export class DynamicFormComponent {
  @Input() question!: QuestionBase<string>;
  @Input() form!: UntypedFormGroup;
  @Input() readonly: boolean;

  constructor(
    public metadataService: MetadataService,
    public htmlLengthService: HtmlLengthService
  ) {}

  get isValid() {
    if (this.question.controlType == "email") {
      return (
        this.form.controls[this.question.key + "value"].valid &&
        this.form.controls[this.question.key + "domain"].valid
      );
    } else {
      if (!this.form.controls[this.question.key].valid) {
        console.log(this.form.controls[this.question.key].errors);
      }
      return this.form.controls[this.question.key].valid;
    }
  }
}
