<div class="NgxEditor__Wrapper" [formGroup]="groupName">
  <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
  <ngx-editor
    [editor]="editor"
    [ngModel]="value"
    [disabled]="readonly"
    [placeholder]="placeholder"
    [formControlName]="controlName"
  ></ngx-editor>
</div>
